<template>
  <div>
    <Toast />
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Crear usuarios</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2" @click="openNewUser">Nuevo</button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5">
        <DataTable :value="users" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Search"/>
              </span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="name" header="Nombre" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{slotProps.data.name}}
            </template>
          </Column>
          <Column field="username" header="Nombre de usuario" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nombre de usuario</span>
              {{slotProps.data.username}}
            </template>
          </Column>
          <Column field="email" header="Correo Electrónico" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Correo Electrónico</span>
              {{slotProps.data.email}}
            </template>
          </Column>
          <Column field="roles" header="Rol Principal" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Rol Principal</span>
              {{getRole(slotProps.data)}}
            </template>
          </Column>

          <Column>
            <template #body="slotProps">
              <div class="flex items-center justify-center">
                <a class="flex items-center mr-3 text-theme-10" href="javascript:;" @click="editUser(slotProps.data)">
                  <CheckSquareIcon class="w-6 h-6 mr-4" />
                </a>
                <a class="flex items-center mr-3 text-theme-5" href="javascript:;" @click="confirmDeleteUser(slotProps.data)">
                  <TrashIcon class="w-6 h-6 mr-4" />
                </a>
              </div>
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
    <Dialog v-model:visible="userDialog" :style="{width: '450px'}" header="Usuarios" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="name">Nombre</label>
        <InputText type="text" id="name" v-model.trim="user.name" required="true"  />
      </div>
      <div class="p-field">
        <label for="username">Nombre de usuario</label>
        <InputText type="text" id="username" v-model.trim="user.username" required="true"  />
      </div>
      <div class="p-field">
        <label for="email">Correo electrónico</label>
        <InputText type="text" id="email" v-model.trim="user.email" required="true"  />
      </div>
      <div class="p-field">
        <label for="password">Contraseña</label>
        <InputText type="password" id="password" v-model.trim="user.password" required="true"  />
      </div>
      <div class="p-field">
        <label for="role">Rol</label>
        <Dropdown id="role" v-model="user.role" :filter="true" :options="optionsRole" optionLabel="name" optionValue="id" placeholder="Select" />
      </div>


      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveUser">Grabar</button>
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteUserDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="user">¿Estás seguro de que quieres eliminar <b>{{user.name}}</b>?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUserDialog = false"/>
        <Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteUser" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import AcceptedOrder from '../service/AcceptedOrder';
import {FilterMatchMode} from "primevue/api";
import axios from "axios";
import {API_LOCAL_URL} from "@/config";

export default {
  data() {
    return {
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      loading: true,
      selectedServices: null,
      users: [],
      user: {
        "id": null,
        "name": null,
        "username": null,
        "email": null,
        "password": null,
        "role": null
      },
      submitted: true,
      userDialog: false,
      deleteUserDialog: false,
      optionsRole: [
        {
          "id": 1,
          "name": "Administrador",
        },
        {
          "id": 2,
          "name": "Comercial DSV",
        },
        {
          "id": 7,
          "name": "Comercial BDP",
        },
        {
          "id": 3,
          "name": "Comercial",
        },
        {
          "id": 4,
          "name": "Operaciones",
        },
        {
          "id": 5,
          "name": "Trafico",
        },
        {
          "id": 6,
          "name": "Administrador de cargas pendientes",
        },
      ],
    }
  },
  AcceptedOrder: null,
  created() {
    this.AcceptedOrder = new AcceptedOrder();
  },
  async mounted() {
    this.AcceptedOrder.getUsers().then(data => {
      this.users = data;
      this.loading = false;
    })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error al traer los datos',
            detail: 'Error al traer los datos',
            life: 5000
          });
          this.loading = false;
        });
  },
  methods: {
    getRole(data){
      if (typeof(data.roles) !== "undefined" && data.roles !== null) {
        var role = '';
        switch (data.roles[0].name) {

          case 'Admin':
            role = 'Administrador';
              break;

          case 'Panalpina':
            role = 'Comercial DSV';
            break;

          case 'Bdp':
            role = 'Comercial BDP';
            break;

          case 'Otros':
            role = 'Comercial';
            break;

          case 'Otm':
            role = 'Administrador de cargas pendientes';
            break;

          default:
            role = data.roles[0].name;

        }
        return role;
      }
      return null;
    },
    openNewUser() {
      Object.assign(this.$data.user, this.$options.data().user);
      this.submitted = false;
      this.userDialog = true;
    },
    async saveUser() {
      this.submitted = true;
      this.userDialog = false;
      this.loading = true;

      var axios1, method = 'post', slug = '';

      if (this.user.id) {
        method = 'put';
        slug = '/'+this.user.id;
      }

      axios1 = await new Promise((resolve)=>{
        this.sendApiLocal('user'+slug, this.user, method)
            .then(response => {
              resolve(response);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1]).then(() => {
        if (method == 'put') {
          this.users[this.findIndexById(axios1.data.id)] = axios1.data;
          this.$toast.add({severity:'success', summary: 'Exito!', detail: 'Actualizado correctamente', life: 5000});
        }
        else {
          this.users.push(JSON.parse(JSON.stringify(axios1.data)));
          this.$toast.add({severity:'success', summary: 'Exito!', detail: 'Creado correctamente', life: 5000});
        }
        this.loading = false;
      });
    },
    editUser(data) {
      this.user.id = data.id;
      this.user.name = data.name;
      this.user.username = data.username;
      this.user.email = data.email;
      this.user.password = data.password;
      this.user.role = null;
      if (typeof(data.roles) !== "undefined" && data.roles !== null) {
        this.user.role = data.roles[0].id;
      }
      this.submitted = false;
      this.userDialog = true;
    },
    confirmDeleteUser(data) {
      this.user.id = data.id;
      this.user.name = data.name;
      this.submitted = false;
      this.deleteUserDialog = true;
    },
    async deleteUser() {
      this.loading = true;
      this.deleteUserDialog = false;
      var axios1, method = 'post', slug = '';
      if (this.user.id) {
        method = 'delete';
        slug = '/'+this.user.id;
      }
      axios1 = await new Promise((resolve) => {
        this.sendApiLocal('user'+slug, [], method)
            .then(response => {
              resolve(response);
            })
            .catch(() => {
              this.loading = false;
              this.$toast.add({
                severity: 'error',
                summary: 'Error de solicitud',
                detail: 'No se ha podido procesar la solicitud',
                life: 5000
              });
            });
      })
      Promise.all([axios1]).then(() => {
        this.loading = false;
        this.users = this.users.filter(val => val.id !== this.user.id);
        this.user = {};
        this.$toast.add({severity:'success', summary: 'Exito!', detail: 'Eliminado Correctamente', life: 3000});
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    sendApiLocal(slug, data, method){
      const config = {
        headers: {
          "Authorization": "Bearer " + this.$store.state.auth.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };
      if (method == 'post') {
        return axios.post(API_LOCAL_URL + slug, data, config);
      } else if (method == 'delete'){
        return axios.delete(API_LOCAL_URL + slug, config);
      } else {
        return axios.put(API_LOCAL_URL+slug, data, config);
      }
    },
  }
}
</script>

<style scoped lang="scss">
.p-component{
  font-size: 0.875rem !important;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        > td {
          text-align: left;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
</style>